<template>
  <vs-popup class="sm:popup-w-80" title="Detail Jurnal" :active="isActive" v-on:update:active="emitModalIsActive">
    <div :class="{'pointer-events-none opacity-50': isLoading}">
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">No. Jurnal</label>
          <vs-input class="w-full" :value="data.no_jurnal" readonly/>
        </div>
        <div class="vx-col sm:w-3/12 w-full">
          <label class="ml-1 text-xs">Journal Date</label>
          <vs-input class="w-full" :value="data.tgl_jurnal" readonly/>
        </div>
        <div class="vx-col sm:w-4/12 w-full">
          <label class="ml-1 text-xs">Total</label>
          <v-money class="w-full" type="number" :value="data.total" readonly/>
        </div>
        <div class="vx-col sm:w-2/12 w-full">
          <label class="ml-1 text-xs">Files</label>
          <vs-button color="warning" v-if="data.files_url && data.files_url.length > 0" type="filled" size="small" icon-pack="feather" icon="icon-file" class="px-2" @click="showFiles(data.files_url)">Lihat Files</vs-button>
          <div v-else class="text-sm">-</div>
        </div>
      </div>
      <div class="vx-row mb-3">
        <div class="vx-col sm:w-1/1 w-full">
          <label class="ml-1 text-xs">Description</label>
          <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" :value="data.keterangan" readonly/>
        </div>
      </div>

      <div class="vx-row mb-3">
        <div class="vx-col sm:w-12/12 w-full">
          <vs-table :data="data.details" stripe>
            <template slot="thead">
              <vs-th class="whitespace-no-wrap text-center">#</vs-th>
              <vs-th class="whitespace-no-wrap text-center">Account</vs-th>
              <vs-th class="whitespace-no-wrap text-center">Description</vs-th>
              <vs-th class="whitespace-no-wrap text-center">Debit</vs-th>
              <vs-th class="whitespace-no-wrap text-center">Credit</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr class="text-sm" v-for="(item, index) in data" :key="item.uuid">
                <vs-td>{{ index + 1 }}</vs-td>
                <vs-td class="whitespace-no-wrap">{{ item.kode_coa }} - {{ item.nama_coa }}</vs-td>
                <vs-td class="whitespace-no-wrap">{{ item.keterangan }}</vs-td>
                <vs-td class="whitespace-no-wrap text-right">{{ item.debet | idr }}</vs-td>
                <vs-td class="whitespace-no-wrap text-right">{{ item.kredit | idr }}</vs-td>
              </vs-tr>
              <vs-tr class="text-sm">
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td></vs-td>
                <vs-td class="whitespace-no-wrap text-right font-bold">{{ footerDetails.grandTotalDebet | idr }}</vs-td>
                <vs-td class="whitespace-no-wrap text-right font-bold">{{ footerDetails.grandTotalKredit | idr }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </div>

    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>
  </vs-popup>
</template>

<script>
import GeneralJurnalRepository from '@/repositories/accounting/general-jurnal-repository'
import VMoney from '@/views/components/v-money/VMoney'
import FilesViewer from '@/views/components/files-viewer/FilesViewer'
import _ from 'lodash'

export default {
  name: 'GeneralJurnalView',
  props: ['isActive', 'idJurnal'],
  components: {
    VMoney,
    FilesViewer
  },
  computed: {
    footerDetails () {
      return {
        grandTotalKredit: _.sumBy(this.data.details, item => item.debet),
        grandTotalDebet: _.sumBy(this.data.details, item => item.kredit)
      }
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        this.initData()
      }
    }
  },
  data () {
    return {
      isLoading: false,
      data: {
        details: []
      },
      modalFiles: {
        filesUrl: [],
        active: false
      }
    }
  },
  methods: {
    initData () {
      this.isLoading = true

      GeneralJurnalRepository.show(this.idJurnal)
        .then(response => {
          this.data = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    showFiles (filesUrl) {
      this.modalFiles.filesUrl = filesUrl
      this.modalFiles.active = true
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
      if (!isActive) {
        this.resetData()
      }
    }
  }
}
</script>
